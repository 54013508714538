<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('label_contractual_payments')}}</b-breadcrumb-item>
            </div>
        </div>

        <b-card no-body>

            <b-card-body class="pb-0">

                <b-row>

                    <b-col cols="12">
                        <b-row class="mb-1">

                            <b-col cols="12" md="3" class="mb-1">
                                <label>{{$t('label_type_of_ageement')}}</label>
                                <v-select

                                        class="sort--select form-control"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        v-model="filterData.agreement_type_id"
                                        :options="agreementTypes"
                                        label="name"
                                        :placeholder="$t('label_type_of_ageement')"
                                        :reduce="val => val.id"
                                        @option:selected="fillPaymentTypes($event);filterData.payment_type = null"

                                >
                                    <template v-slot:option="option">
                                        <!--<span :class="option.icon"></span>-->
                                        <span class="">{{ option.name }}</span>
                                    </template>
                                    <template v-slot:selected-option="option">
                                        <!--<span :class="option.icon"></span>-->
                                        <span >{{ option.name}}</span>
                                    </template>

                                </v-select>
                            </b-col>
                            <b-col cols="12" md="3" class="mb-1" >
                                <label>{{$t('label_type_of_payment')}}</label>
                                <v-select
                                        class="sort--select form-control"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        v-model="filterData.payment_type"
                                        :options="paymentTypes"
                                        label="name"
                                        :placeholder="$t('label_type_of_payment')"
                                        :reduce="val => val.alias"

                                >
                                    <template v-slot:option="option">
                                        <!--<span :class="option.icon"></span>-->
                                        <span class="">{{ $t(option.trans_index) }}</span>
                                    </template>
                                    <template v-slot:selected-option="option">
                                        <!--<span :class="option.icon"></span>-->
                                        <span >{{ $t(option.trans_index)}}</span>
                                    </template>

                                </v-select>
                            </b-col>

                            <b-col cols="12" md="3" class="mb-1">
                                <label>{{$t('label_status_of_payment')}}</label>
                                <v-select

                                        class="sort--select form-control"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        v-model="filterData.payment_status"
                                        :options="paymentStatuses"

                                        label="alias"
                                        :placeholder="$t('label_status_of_payment')"
                                        :reduce="val => val.alias"

                                >
                                    <template v-slot:option="option">
                                        <!--<span :class="option.icon"></span>-->
                                        <span class="">{{ $t(option.label) }}</span>
                                    </template>
                                    <template v-slot:selected-option="option">
                                        <!--<span :class="option.icon"></span>-->
                                        <span >{{ $t(option.label)}}</span>
                                    </template>

                                </v-select>
                            </b-col>

                        </b-row>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>

        <b-card
                no-body
                class="mb-0"
        >

            <b-col
                    cols="12"
                    md="12"
                    class=" mb-1 mt-1"
            >


                <div class="pb-2 mt-1 table-top-toolbar">


                            <b-row>

                                <b-col
                                        cols="12"
                                        md="6"
                                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                                >

                                    <v-select
                                            v-model="tableData.perPage"
                                            :searchable="false"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            :options="tableData.perPageOptions"
                                            :clearable="false"
                                            class="per-page-selector d-inline-block mx-50"
                                    />
                                    <column-chooser v-model="selectedColumns" :not-touch="['id']"  :columns="columnsDefs"></column-chooser>

                                </b-col>

                            </b-row>
                </div>

                <div class="table-container-wrap">
                    <b-table
                            :ref="PREFIX+'_TABLE_pay'"
                            class="position-relative transited-table"
                            :items="tableItems"
                            responsive
                            no-border-collapse
                            :busy="isBusy"
                            :fields="selectedColumns"
                            primary-key="id"
                            :sort-by.sync="tableData.sortBy"
                            no-local-sorting
                            :sort-desc.sync="tableData.sortDesc"
                            show-empty
                            :tbody-transition-props="{ name: 'flip-list'}"
                            :empty-text="$t('label_no_table_data')"
                    >

                        <template #head()="data">
                            <span :id="data.column" class="text-secondary d-inline-block">{{ $t(data.label).ucFirst() }}</span>
                            <b-tooltip
                                    offset="50"
                                    placement="bottom"
                                    :target="data.column"
                                    triggers="hover"
                                    no-fade
                                    delay="1000"
                            >{{ $t(data.label) }}
                            </b-tooltip>
                        </template>

                        <template #cell(details_control)="data">

                            <feather-icon
                                    :color="data.detailsShowing? 'danger' : '#7c70f9'"
                                    @click="getDetails(data)"
                                    size="20"
                                    class="cursor-pointer"
                                    :icon="data.detailsShowing? 'MinusCircleIcon' : 'PlusCircleIcon'"
                            />

                        </template>

                        <template #row-details="{item,toggleDetails}">
                            <b-card>
                                <!--<h1>{{item.id}}</h1>-->
                                <template v-if="item.corrections.length > 0">
                                    <!--<h1 v-for="cor in  item.corrections">{{cor.new_net_payment}}</h1>-->
                                    <b-table-simple small caption-top responsive no-border-collapse>
                                        <b-thead head-variant="light">
                                            <b-tr>
                                                <b-th>{{$t('label_old_value')}}</b-th>
                                                <b-th>{{$t('label_changed') + ' ' + $t('label_changed_to')}}</b-th>
                                                <b-th>{{$t('label_creating_date')}}</b-th>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr v-for="cor in  item.corrections">
                                                <b-td>{{cor.old_net_payment.formatPrice()}}</b-td>
                                                <b-td>{{cor.new_net_payment.formatPrice()}}</b-td>
                                                <b-td>{{formatDate(cor.created_at, 'DD.MM.YYYY HH:mm', 'unix')}}</b-td>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </template>

                                <p v-else>{{$t('label_no_records_available')}}</p>

                            </b-card>
                        </template>
                        <template #cell(deal_number)="data">
                            <router-link
                                    v-if="$can('view', 'agreement.tab_details') || $can('view', 'deal.tab_details')"
                                    :to="{name: (data.item.deal_id? 'deal' : 'agreement')+'-details', params:{id:data.item.deal_id?data.item.deal_id: data.item.agreement_id}}">
                                {{data.item.deal_number}}
                            </router-link>
                            <template v-else>
                                {{data.item.deal_number}}
                            </template>
                        </template>
                        <template #cell(counter)="data">
                            {{data.index + 1}}
                        </template>
                        <template #cell(pay_in_cash)="data">
                            {{data.item.pay_in_cash == 1 ? $t('label_yes') : '--'}}
                        </template>
                        <template #cell(pay_for_each_participation)="data">
                            {{data.item.pay_for_each_participation == 1 ? parseInt(data.item.each_participation_days) :
                            '--'}}
                        </template>
                        <template #cell(pay_after_month)="data">
                            {{data.item.pay_after_month == 1 ? parseInt(data.item.pay_after_month_days) : '--'}}
                        </template>
                        <!--for report-->
                        <template #cell(report_file)="data">
                            <a target="_blank" :href="$base_url + data.item.report_dir + data.item.report_file">
                                <feather-icon icon="FileIcon" size="16"></feather-icon>
                            </a>
                            <!--{{data.item.pay_after_month == 1 ? parseInt(data.item.pay_after_month_days)  : '&#45;&#45;'}}-->
                        </template>


                        <template #cell(actions)="data">
                            <span v-if="data.item.type == 'each_participation_activity' || data.item.type == 'success_fee' || data.item.type == 'single_for_meeting'"></span>
                            <template v-else>
                                <button style="min-width: 100px" v-if="data.item.is_paid == 0"
                                        class="btn-outline-danger rounded-pill"
                                        @click="changePayStatus(data.item.id , 'payment')">
                                    {{ $t('label_settlement') }}
                                </button>
                                <template v-if="data.item.is_paid == 1">
                                    <template v-if="data.item.need_apply_correction == 0">
                                        <span class="badge text-primary d-block">{{ $t('label_settled') }}</span>
                                        <button style="min-width: 100px" class="btn-outline-primary rounded-pill"
                                                @click="correctionForId = data.item.id;$bvModal.show('pay_correction-modal')">
                                            {{ $t('label_correct') }}
                                        </button>
                                    </template>
                                    <button style="min-width: 100px" v-else class="btn-outline-warning rounded-pill"
                                            @click="applyCorrection(data.item.id)">
                                        {{ $t('label_correction') }}
                                    </button>
                                </template>

                            </template>
                        </template>


                    </b-table>
                </div>
                <!--pagination-->
                <div class="mx-2 mb-2">
                    <b-row>

                        <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                        </b-col>
                        <!--Pagination -->
                        <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >

                            <b-pagination
                                    v-model="tableData.currentPage"
                                    :total-rows="tableTotal"
                                    :per-page="tableData.perPage"
                                    first-number
                                    last-number
                                    class="mb-0 mt-1 mt-sm-0"
                                    prev-class="prev-item"
                                    next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                    />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>
            </b-col>
        </b-card>

        <pay-correction-modal
                @item-added="refreshDataTable()"
                :correction-for-id="correctionForId"
                :is-deal="false"
        ></pay-correction-modal>
    </div>
</template>

<script>
    import {
        BCard, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip,
        BTbody, BThead, BTh, BTr, BTableSimple, BTd
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import columnChooser from '@/views/components/columnChooser'
    import payCorrectionModal from './../agreement/modals/processPayCorrectionModal'

    import {PAYMENT_PREFIX as PREFIX, AGREEMENT_PREFIX as MODULE_PREFIX, getAgrmPaymentTypes, paymentStatuses} from './../agreement/moduleHelper'

    export default {
        components: {BCard, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem,
            BPagination, BCardBody, BFormCheckbox, BCardHeader, BTooltip, BTbody, BThead, BTh, BTr, BTableSimple, BTd,
            vSelect,
            columnChooser,
            payCorrectionModal
        },
        props: ['tableType'],
        data() {
            return {
                MODULE_PREFIX,
                PREFIX,


                selectedColumns:[],

                correctionForId: false,
                columnsDefs: [

                    // {label: '№', key: 'id', thClass: 'hidden', tdClass: 'hidden',},
                    {label: 'label_#', key: 'details_control'},
                    {label: 'label_deal_number', key: 'deal_number'},
                    {label: 'label_payment_type', key: 'payment_trans', sortable: false},
                    {
                        label: 'label_payment_amount_netto', key: 'net_payment', formatter(v) {
                            return v ? v.formatPrice() : '--'
                        }, sortable: false
                    },
                    {
                        label: 'label_payment_amount_gross', key: 'gross_payment', formatter(v) {
                            return v ? v.formatPrice() : '--'
                        }, sortable: false
                    },
                    {
                        label: 'label_the_monthly_lump_sum_includes_a_limit_in_quantity',
                        key: 'lump_sum_months',
                        formatter(v) {
                            return v ? v : '--'
                        },
                        sortable: false
                    },
                    {label: 'label_paid_in_cash', key: 'pay_in_cash', sortable: false},
                    {
                        label: 'label_before_each_implementation_on_time',
                        key: 'pay_for_each_participation',
                        sortable: false
                    },
                    {
                        label: 'label_payment_after_the_end_of_the_month_on_time',
                        key: 'pay_after_month',
                        sortable: false
                    },
                    {
                        label: 'label_invoice_issue_date', key: 'deadline_at', formatter(v) {
                            return formatDate(v, 'DD.MM.YYYY', 'unix')
                        }, sortable: false
                    },
                    {label: 'label_action', key: 'actions'}

                ],

                isBusy: false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 100,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [8, 10, 25, 50, 100],
                },
                filterData: {
                    agreement_type_id: '',
                    payment_type: '',
                    payment_status: '',
                },

                agreementTypes: [],
                paymentTypes: [],
                paymentStatuses

            }
        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            }
        },

        created() {

            this.getPageData();

            this.columnsDefs = this.columnsDefs.filter((el) => {
                if (el.key == 'actions' && !this.$can('edit', 'agreement.tab_payments')) {
                    return false;
                }
                return true;
            });

            this.refreshDataTable();

            this.fillPaymentTypes();
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },
        },

        methods: {

            getPageData() {
                this.async('get', '/select_options', {params:{options:['agreement_types']}}, function(res){
                    this.agreementTypes  =  res.data.agreement_types;
                });
            },

            fillPaymentTypes(agreementType){
               this.paymentTypes = getAgrmPaymentTypes(agreementType ? agreementType.name_alias : null);
            },

            getDetails(row) {
                let isClosed = !row.detailsShowing || row.detailsShowing === false;

                // console.log(row);

                let is_sending = false;
                if (isClosed) {
                    is_sending = true;
                    // let correction = [];

                    this.async('get', '/' + this.MODULE_PREFIX + '_' + this.PREFIX + '_corrections', {
                        params:{agreement_payment_id: row.item.id}
                    }, function (res) {
                        row.item.corrections = res.data;
                        if (isClosed) {
                            row.toggleDetails();
                        }
                    });
                } else {
                    row.toggleDetails();
                }

            },
            applyCorrection(id) {
                this.async('put', '/' + this.MODULE_PREFIX + '_' + this.PREFIX + 's/api_applyCorrection/'+id, {
                    // params:{agreement_payment_id: row.item.id}
                }, function (res) {
                    this.refreshDataTable();
                });
            },
            changePayStatus(id, type) {

                this.async('post', '/' + this.MODULE_PREFIX + '_' + this.PREFIX + 's/api_changePayStatus/', {
                    id: id, status: 'is_paid', type: type
                }, function (res) {
                    this.refreshDataTable();
                });
            },
            refreshDataTable: function () {
                this.isBusy = true;

                this.async('get', '/agreement_payments', {

                    params: {
                        // ['agreement_id']: this.isDeal? this.moduleItem.agreement_id : this.moduleItem.id,
                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,

                        agreement_type_id: this.filterData.agreement_type_id,
                        payment_status: this.filterData.payment_status,
                        payment_type: this.filterData.payment_type,
                    }
                }, function (resp) {
                    // console.log('reeesp', resp);
                    this.tableItems = resp.data.items.map((el) => {
                        el.detailsShowed = false;
                        return el;
                    });
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                    let self = this;

                });
            },


        },


    }
</script>

